.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

input[type="file"].map-users {
  display: none;
}

.custom-file-upload .anticon{
  line-height: 2.4 !important
}
.custom-file-upload {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%)
}
h1,
h2,
h3 {
    /* font-family: 'Kaushan Script', cursive; */
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}
.form-inner {
  display: flex;
  gap: 10px;
  margin-top: 11px;
}

.form-inner .ant-row.ant-form-item {
  width: 50%;
}