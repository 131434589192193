@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");
footer {
    position: fixed;
    padding: 20px;
    text-align: center;
    background-color: #ded7d7;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
}
body {
    font-family: "Inter", sans-serif;
}
.ant-layout {
    background: #f5f5f5 !important;
}
body h2 {
    font-size: 25px;
    font-weight: 600;
}
body h4 {
    font-size: 20px;
    font-weight: 500;
}
.green-clr {
    color: #073141;
}
.blue-clr {
    color: #161fff;
}
.purple-clr {
    color: #b100ef;
}
.ant-layout .ant-layout-header {
    height: 64px;
    padding: 0 25px;
    line-height: 56px;
    background: rgb(255, 255, 255);
}
.ant-layout .ant-layout-header h2 {
    display: inline-block;
    padding: 0px 15px 0;
    color: #073141;
    font-size: 25px;
    font-weight: 700;
    transform: translateY(5px);
}
.ant-layout-header .name-role {
    display: inline-flex;
    flex-direction: column;
    margin-left: 0.5rem;
}
.name-role span {
    height: 15px;
    color: #073141;
}
.name-role small {
    opacity: 0.7;
}
.logo {
    margin: 14px;
}

a {
    text-decoration: none !important;
}

.anticon {
    vertical-align: 0;
}

h1,
h2,
h3 {
    /* font-family: 'Kaushan Script', cursive; */
    font-family: "Inter", sans-serif;
    font-weight: 700;
}

.myform {
    /* position: relative;
	display: -ms-flexbox;
	display: flex;
	padding: 1rem;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: 1.1rem;
	outline: 0;
	max-width: 500px; */
    position: absolute;
    top: 45%;
    left: 50%;
    margin: -250px 0 0 -160px;
    width: 320px;
    min-height: 320px;
    padding: 36px;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
}

.tx-tfm {
    text-transform: uppercase;
}

.mybtn {
    border-radius: 50px;
}

.login-or {
    position: relative;
    color: #aaa;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.version-btn {
    display: flex;
}

.span-or {
    display: block;
    position: absolute;
    left: 50%;
    top: -2px;
    margin-left: -25px;
    background-color: #fff;
    width: 50px;
    text-align: center;
}

.hr-or {
    height: 1px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.google {
    color: #666;
    width: 100%;
    height: 40px;
    text-align: center;
    outline: none;
    border: 1px solid lightgrey;
}

img {
    max-width: 100%;
}

.profileDetails .badge {
    color: #ffffff;
    font-size: 12px;
}

.listingPage {
    margin: 30px 0;
}

.listingPage .ListItems {
    margin-top: 30px;
}

.additional {
    font-size: 16px;
}

.additional:last-child {
    margin-top: 15px;
}

.additional .price {
    color: #e6c041;
    font-weight: 700;
    font-size: 20px;
}

.additional .price .socialIcon a {
    color: #4e4e4e;
    font-weight: normal;
    margin: 0 0 0 10px;
}

.category {
    color: #e6c041;
    font-weight: 700;
}

.gx-user-popover {
    margin: 0;
    padding: 0;
}

.gx-user-popover li {
    display: block;
}

.ratingBox {
    margin: 15px 0 0 0;
}

.proImageBox .ant-card-body {
    display: none;
}

.ant-layout-has-sider .ant-layout-sider {
    z-index: 10;
}

.ant-layout-has-sider .ant-layout-footer {
    padding: 4px;
}

.ant-layout-has-sider .ant-layout-sider,
.ant-layout-has-sider .ant-menu-dark {
    background: #383434;
}

.ant-layout-has-sider .ant-menu.ant-menu-dark .ant-menu-item-selected {
    background: #e08300;
    border-radius: 12px;
}
ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
    padding: 0 0.8rem;
}
.ant-layout-has-sider .ant-menu-dark .ant-menu-item {
    color: #fff6f1;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    padding-left: 1rem !important;
    border-radius: 12px;
    height: auto;
    padding: 0.4rem 1rem;
}

.ant-layout-has-sider .ant-menu-dark .ant-menu-item:hover {
    background: #e08300;
    border-radius: 12px;
}

.ant-layout-has-sider .ant-menu-dark .ant-menu-submenu {
    color: #4f4f4f;
}

.ant-layout-has-sider .ant-menu-dark .ant-menu-submenu-title {
    color: #fff6f1;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    padding-left: 1rem !important;
    border-radius: 12px;
}

.ant-layout-sider-collapsed .logo {
    width: 35px;
    margin-left: 22px;
    overflow: hidden;
}

.ant-layout-sider-collapsed .logo img {
    width: 140px;
    max-width: inherit;
}

body a {
    color: #e6c041;
}

body a:hover {
    color: #e6c041;
    text-decoration: underline;
}

.ant-layout-has-sider .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    box-shadow: none;
    background: transparent;
    margin-left: 0.6rem;
}

.ant-layout-has-sider .ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item {
    color: #ffffff;
}

.ant-layout-has-sider .ant-menu-dark .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-layout-has-sider .ant-menu-dark .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-layout-has-sider .ant-menu-dark .ant-menu-submenu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-layout-has-sider .ant-menu-dark .ant-menu-submenu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after {
    background: #fff;
}
.ant-layout-has-sider .ant-menu-item .anticon {
    font-size: 20px;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item {
    height: auto !important;
}
.overflowBox {
    overflow: hidden;
}

#root,
.ant-layout {
    height: 100%;
}

.ant-statistic .ant-statistic-title {
    font-size: 20px;
}

.box {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.375rem;
}

.box-default {
    background-color: #fff;
}

.box-default .box-header {
    padding: 0.75rem 1.25rem;
    border-radius: 0.375rem 0.375rem 0 0;
    font-size: 0.875rem;
    border-bottom: 1px solid transparent;
    font-weight: 500;
}

.box-default .box-body {
    padding: 1.25rem;
    border-radius: 0 0 0.375rem 0.375rem;
}

body .ant-switch-checked {
    background-color: #17a2b8;
}

.gx-login-container {
    position: relative;
    height: 100%;
    padding-bottom: 30px;
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: url("../../img/login-banner.png") no-repeat;
    background-size: cover;
}
.gx-login-container::before {
    background: url(../../img/login-art.png) no-repeat;
    height: 100%;
    width: 100%;
    background-position: left center;
    content: "";
    position: absolute;
    background-size: 60%;
}
.gx-login-content {
    max-width: 512px;
    width: 100%;
    padding: 5% 5% 3%;
    background-color: #fff;
    box-shadow: 0px 21px 38px rgba(0, 0, 0, 0.13);
    border-radius: 27px;
    margin-left: auto;
    margin-right: 0;
}
.gx-login-content h2 {
    color: #073141;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 2rem;
}
.gx-login-header {
    margin-bottom: 30px;
    text-align: center;
}

.gx-text-center {
    text-align: center !important;
}
.gx-login-content a {
    color: #e08300;
}
.gx-login-title {
    color: #535353;
    font-size: 24px;
}
.gx-login-content .ant-form-item label {
    display: flex;
    flex-direction: row-reverse;
    color: #073141;
    font-size: 16px;
    font-weight: 500;
    height: 20px;
    align-items: center;
    justify-content: flex-end;
}
.gx-login-content .ant-form-item input {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    height: 48px;
}
button.ant-btn.ant-btn-primary {
    background: #e08300;
    border-radius: 6px;
    border: 0;
    height: 40px;
    /* font-size: 18px; */
    font-weight: 600;
}
.gx-login-content .ant-form-item label::after {
    display: none;
}
.l_hgt0 .ant-form-item-label {
    line-height: 0px !important;
}

.img-104 {
    height: 104px;
}

.ht_200 {
    height: 200px;
    overflow: hidden;
}

.ht_300 {
    height: 300px;
    overflow: hidden;
}

.ht_150 {
    height: 150px;
    overflow: hidden;
}

.cursor-pointer {
    cursor: pointer;
}

.left-icon span {
    height: 70px;
    width: 70px;
    display: inline-block;
    text-align: center;
    line-height: 70px;
    border-radius: 100%;
}
.left-icon.skyblue span {
    background: #20bafc;
}
.left-icon.red span {
    background: #ff5b65;
}
.left-icon.orange span {
    background: #fc6f20;
}
.count-no {
    color: #383434;
    font-size: 25px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.count-no small {
    font-size: 18px;
    opacity: 0.7;
}
.bottom-text {
    color: #383434;
    font-size: 20px;
    font-weight: 500;
    opacity: 0.7;
    border-top: 1px solid #ddd;
    margin-top: 1rem;
    padding-top: 0.6rem;
}
.ant-card.ant-card-bordered.ant-card-hoverable {
    border-radius: 5px;
    border: 0;
}
.drop-select .ant-select-selection.ant-select-selection--single {
    border: 0;
    box-shadow: none;
    outline: none;
    width: 120px;
}
.profile-form .profile-picture {
    max-width: 200px;
    height: 200px;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #ddd;
}
.profile-menu a {
    display: flex;
    align-items: center;
    color: #383434;
}
.profile-menu a i {
    margin-right: 8px;
}
h2.page-heading {
    display: inline-block;
    color: #073141;
    font-size: 25px;
    font-weight: 700;
}
.profile-form .ant-form-item label {
    position: relative;
    margin: 0;
    font-weight: 600;
}
.profile-form .ant-form-item input.ant-input,
input.ant-input {
    height: 40px;
}
.muser-dropdown-link {
    border: 1px solid #383434;
    border-radius: 9px;
    color: #383434;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    font-size: 20px;
    line-height: 25px;
}
.table thead {
    background: rgb(56 52 52 / 5%);
    border: 0;
}
.table thead th {
    border: 0;
    border-bottom: 0 !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #383434;
}
.table td {
    border-top: 1px solid rgba(6, 21, 81, 0.07) !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #383434
}
.ant-switch-loading-icon, body .ant-switch::after {
    background-color: #E08300;
    content: ' ';
}
body .ant-switch-checked {
    background-color: #383434;
}
.action-icons button{
    font-size: 18px;
    color: #666;
    display: inline-flex;
}
.action-icons button:hover{
    color: #E08300;
}
.clr-inhrit:hover{
    color: #383434;
}
button.ant-btn.ant-btn-outline {
    height: 40px;
    color: #383434;
    border-color: #383434;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
}
body .ant-modal-header {
    background: rgb(56 52 52 / 5%);
    border: 0;
    border: 4px solid #fff;
}
body .ant-select-selection {
    height: 40px;
}
.ant-select-selection--single .ant-select-selection__rendered {
    line-height: 40px;
}
td.action-icons {
    display: flex;
    align-items: center;
}
.mobile-logos{
    display: none;
}
aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed{
    flex: 0 !important;
    max-width: 0 !important;
    min-width: 0 !important;
    width: 0 !important;
    overflow: hidden;
}

/**** secret comp *****/
.secret_comp_main button {
    background: transparent;
    border: 0;
    color: inherit;
    font-weight: inherit;
    padding: 0 0px 0 5px;
    position: relative;
    top: -2px;
   }
   .secret_comp_main .dropdown {
     display: inline;
   }
   .secret_comp_main button:hover {
      background: transparent;
      color: inherit;
   }
   .secret_comp span {
       display: block;
       text-align: center;
       font-weight: 700;
       margin-bottom: 20px;
   }
   .secret_comp table.inner-table {
       width: 100%;
   }
   .secret_comp > table > tbody > tr > td {  
      padding: 20px 0;
   }
   .secret_comp table.inner-table img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.ladder span {
    width: 50%;
    float: left;
}
.ladder span.reveal {
    width: 50%;
    float: right;
}
td.image_flag {
    position: relative;
}
td.image_flag span {
    position: absolute;
    bottom: -20px;
    left: 20px;
}.secret_comp > table > tbody > tr > td .inner-table {
    border: 1px solid #edeef3;
}
.tipdistribution {
    display: flex;
}
.tipdistribution h2 {
    width: 100%;
}
.tipdistribution .dropdown {
    width: 100%;
}
.tipdistribution .dropdown button {
    background: transparent;
    border: 0;
    color: #3e3434;
    font-weight: 600;
}
.tipdistribution .dropdown i {
    padding: 0 10px;
}
.secret_comp_main h5 {
    text-align: center;
    margin: 20px 0;
}
.tip_container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    border: 2px solid #edeef3;
    border-radius: 30px;
}
.tip_container .tip_inner {
    padding: 10px 30px;
}
.tip_container .tip_inner p {
    margin: 0;
    font-weight: 600;
}
.tip_container .tip_inner img {
    margin: 10px 0 20px;
    width: 60px;
}

.dropdown-menu.show {
    padding: 10px;
    height: 150px;
    overflow-y: scroll;
}
.tipdistribution .dropdown {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 6px;
}

.tipdistribution {
    position: relative;
}
/********* secretcamp **/


   

@media (max-width: 992px) {
    .gx-login-container::before {
        background-size: 100%;
    }
}
@media (max-width:645px){
/********* secretcamp **/
    .secret_comp >table tr > th {
        font-size: 14px;
        padding: 12px 10px;
        text-align: left;
        width: 100px;
    }
    .secret_comp button {
        font-size: 14px;
    }
    .secret_comp table.inner-table td {
        width: 100px;
    }
    .secret_comp table.inner-table tr {
        display: flex;
    }
    .tipdistribution {
        display: block;
        text-align: center;
        margin-top: 20px;
    }
    .tipdistribution .dropdown {
        position: unset;
        display: block;
        margin: 10px 0;
    }
   
/********* secretcamp **/

    /* aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
        margin-left: -80px;
    } */
    aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed{
        width: 0 !important;
        min-width: 0 !important;
        flex: 0 0 0 !important;
    }
    aside.ant-layout-sider.ant-layout-sider-dark {
        position: absolute;
        bottom: 0;
        top: 0;
    }
    .overlay-backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #00000059;
        z-index: 9;
        bottom: 0;
    }
    .mobile-logos {
        display: inline-block;
        margin: auto;
        max-width: 200px;
    }
    .ant-layout-header .name-role {
        display: none;
    }
    .ant-layout .ant-layout-header {
        display: flex;
        align-items: center;
    }
    .ant-layout-sider-collapsed .logo {
        visibility: hidden;
    }
    .table thead th, .table td{
        font-size: 14px;
    }
    h2.page-heading {
        font-size: 18px;
    }
    .left-icon span {
        height: 50px;
        width: 50px;
        line-height: 50px;
    }
    .left-icon span img {
        width: 27px;
    }
    .count-no small {
        font-size: 15px;
    }
    body h2 {
        font-size: 18px;
    }
    body h4 {
        font-size: 16px;
    }
    .bottom-text {
        font-size: 16px;
    }

}

.bunter-filter {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    gap:10px;
    box-shadow: rgba(162, 111, 66, 0.2) 0px 7px 29px 0px;
    width: 700px;
    height: 300px;
    align-items: center;
    color: white;
}
.bunter-filter .ant-select.ant-select-enabled {
    width: 47% !important;
    margin: auto;
   
}
.bunter {
    /* display:flex; */
    width: 600px;
    height: 300px;
    padding: 20px;
    align-items: center;
   display: contents
  
}

span.ant-calendar-picker {
    width: 100%;
}

.bunter-filter span.ant-calendar-picker {
    padding: 0 10px 20px;
}
.bunter-filter {
    padding: 40px 20px;
    align-items: normal;
    grid-gap: 0;
    height: auto;
}

.bunter-filter .ant-select.ant-select-enabled {margin-bottom: 15px;}

.bunter-filter .ant-calendar-picker-input.ant-input {
    height: 40px;
    text-align: left;
}

.bunter-filter button.ant-btn.ant-btn-primary {
    margin-left: 10px;
    width: 100px;
}
button.ant-btn.ant-btn-primary {
    margin-right: 10px;
}

/* article css */
.ant-spin-nested-loading {
    border: 1px solid rgba(0,0,0,.1);
    background: #fff;
    padding: 1.25rem;
    overflow: hidden;
    border-radius: 8px;
}
.ant-form-item label {
    margin-bottom: 0.5rem;
    line-height: 10px;
}
.ant-form-item-label {
    line-height: inherit;
}
.form-inner .ant-row.ant-form-item {
    margin-bottom: 0px;
}
.search_team {
    margin-bottom: 20px;
}
.form-inner.four_itm_div input#article_time{
    width: 100%;
    padding: 19px 10px;
}
.form-inner.four_itm_div .ant-upload button.ant-btn {
    width: 100%;
    padding: 19px 10px;
    display: flex;
    align-items: center;
    position: relative;
    top: 2px;
}
.form-inner.four_itm_div span.ant-time-picker , .form-inner.four_itm_div .ant-upload{
    display: block;
    width: 100%;
}

/*close  */

@media (max-width:575px){
.mobile-bottom-space{
    margin-bottom: 15px !important;
}
}
